import {
  Avatar,
  Box,
  Flex,
  HStack,
  Image,
  Menu,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useMenuButton,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import * as React from 'react'
import { UserContext } from '../../context/UserContextComponent';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiSelector } from 'react-icons/hi';
import Cookies from 'js-cookie';
import { getConfig } from '../../config';

const ProfileMenuButton = () => {
  const { userData } = useContext(UserContext);
  const buttonProps = useMenuButton()
  const name = userData?.first_name

  return (
    <Flex
      as="button"
      {...buttonProps}
      w="full"
      display="flex"
      alignItems="center"
      rounded="lg"
      bg={useColorModeValue("gray.100", "gray.700")}
      px="3"
      py="2"
      fontSize="sm"
      userSelect="none"
      cursor="pointer"
      outline="0"
      transition="all 0.2s"
      _active={{ bg: useColorModeValue("gray.200", "gray.600") }}
      _focus={{ shadow: 'outline' }}
    >
      <HStack flex="1" spacing="3">
        <Avatar
          w="40px"
          h="40px"
          //rounded="md"
          objectFit="cover"
          // src={picture}
          name={name}
          bg={'blurple'}
        />
        <Text>Account Settings</Text>
      </HStack>
      <Box fontSize="lg" color="gray.400">
        <HiSelector />
      </Box>
    </Flex>
  )
}

export const ProfileButton = (props) => {
  const navigate = useNavigate()

  return (
    <Menu>
      <ProfileMenuButton />
      <MenuList
        shadow="lg"
        py="4"
        color={useColorModeValue('gray.600', 'gray.200')}
        px="3"
        maxH={'50vh'}
        overflow='auto'
      >
        <MenuItem
          rounded="md"
          onClick={() => { navigate('/dashboard') }}
        >
          My servers
        </MenuItem>
        <MenuItem
          _hover={{ 'bg': useColorModeValue('red.100', 'red.700') }}
          rounded="md"
          onClick={() => {
            const { tokenName, loginUrl } = getConfig()
            Cookies.remove(tokenName)
            window.location.href = loginUrl
          }}
        >
          Logout
        </MenuItem>
      </MenuList>
    </Menu >
  )
}
