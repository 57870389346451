import React, { Suspense, lazy } from 'react';
import { ProtectedRoute } from './auth/ProtectedRoute';
import { UserContextComponent } from './context/UserContextComponent';
import { DashboardLayout } from './components/layout/DashboardLayout';

// eslint-disable-next-line react/display-name
const Loadable = (Component) => (props) => (
  <Suspense fallback={<></>}>
    <Component {...props} />
  </Suspense>
);
// Auth 
const GuildAuthRedirect = Loadable(lazy(() => import('./components/GuildAuthRedirect')));
const UserAuthRedirect = Loadable(lazy(() => import('./components/UserAuthRedirect')));
const Integration = Loadable(lazy(() => import('./components/Integration')));

// Custom Bot Pages
const CustomBot = Loadable(lazy(() => import('./components/customBot/CustomBot')));
const OnboardingLayout = Loadable(lazy(() => import('./components/customBot/OnBoardingLayout')));

// QnA Bot Pages
const QnABotOnboarding = Loadable(lazy(() => import ('./components/qnaBot/QnABotOnboarding')));
const AddRemoveFAQs = Loadable(lazy(() => import('./components/qnaBot/AddRemoveFAQs')));

// Builder Pages
const BuilderLayout = Loadable(lazy(() => import('./components/builder/BuilderLayout')));
const NothingSelected = Loadable(lazy(() => import('./components/builder/trigger/NothingSelected')));
const Flow = Loadable(lazy(() => import('./components/builder/flow/Flow')));
const NewFlow = Loadable(lazy(() => import('./components/builder/flow/NewFlow')));
const Guilds = Loadable(lazy(() => import('./components/guilds/Guilds')));

// Billing
const Billing = Loadable(lazy(() => import('./components/Billing')));

// Logs
const Logs = Loadable(lazy(() => import('./components/logs/LogsLayout')));

// Members
const MembersLayout = Loadable(lazy(() => import('./components/members/MembersLayout')));

// Member Fields
const MemberFieldsLayout = Loadable(lazy(() => import('./components/memberFields/MemberFieldsLayout')));

// Examples
const ExamplesLayout = Loadable(lazy(() => import('./components/examples/ExamplesLayout')));



const DefaultBotNavigate = () => {
  window.location.href = `/dashboard`;
}

const withUserContext = (Component, props) => {
  return class extends React.Component {
    render() {
      return (
        <UserContextComponent >
          <Component {...props} />
        </UserContextComponent>
      )
    }
  }
}

const routes = [
  {
    path: 'first',
    element: <ProtectedRoute component={withUserContext(OnboardingLayout)} />,
  },
  {
    path: 'guild-oauth',
    element: <ProtectedRoute component={GuildAuthRedirect} />,
  },
  {
    path: 'user-oauth',
    element: <ProtectedRoute component={UserAuthRedirect} />,
  },
  {
    path: 'integration',
    element: <ProtectedRoute component={Integration} />,
  },
  {
    path: 'dashboard',
    element: <ProtectedRoute component={withUserContext(DashboardLayout, { showSidebarBuilder: false })} />,
    children: [
      {
        path: '',
        element: <Guilds />
      },
    ]
  },
  {
    path: 'dashboard/:guildId',
    element: <ProtectedRoute component={withUserContext(DashboardLayout, { showSidebarBuilder: true })} />,
    children: [
      {
        path: 'builder',
        element: <BuilderLayout />,
        children: [
          {
            path: 'trigger/:triggerPath',
            element: <NewFlow />
          },
          {
            path: 'trigger',
            element: <NewFlow />
          },
          {
            path: 'flow/:flowId',
            element: <Flow />
          },
          {
            path: '',
            element: <NothingSelected />
          }
        ]
      },
      {
        path: 'settings',
        element: <OnboardingLayout />
      },
      {
        path: 'custom-bot',
        element: <CustomBot />
      },
      {
        path: 'qna-bot-onboarding',
        element: <QnABotOnboarding />
      },
      {
        path: 'qna-bot-add-remove-faqs',
        element: <AddRemoveFAQs />
      },
      {
        path: 'add-bot',
        element: <OnboardingLayout />
      },
      {
        path: 'billing',
        element: <Billing />
      },
      {
        path: 'logs',
        element: <Logs />
      },
      {
        path: 'members',
        element: <MembersLayout />
      },
      {
        path: 'member-fields',
        element: <MemberFieldsLayout />
      },
      {
        path: 'examples',
        element: <ExamplesLayout />
      },
    ],
  },
  {
    path: 'new',
    element: <ProtectedRoute component={withUserContext(OnboardingLayout, { isSecond: true })} />
  },
  {
    path: '404',
    element: <p>Not Found</p>
  },
  {
    path: '*',
    element: <ProtectedRoute component={withUserContext(DefaultBotNavigate)} />
  },
];

export default routes;
