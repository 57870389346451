import React from "react";
import AuthProvider from "./AuthProvider";

export const ProtectedRoute = ({ component }) => {
  const Component = component;

  return (
    <AuthProvider>
      <Component />
    </AuthProvider>
  );
};