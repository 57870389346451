import { useCallback } from 'react';
import makeRequest from './axios';
import Cookies from 'js-cookie';
import { getConfig } from '../config';

export const useRequest = () => {
  // use blaze token
  const { tokenName } = getConfig();
  const token = Cookies.get(tokenName);

  // memoized the function, as otherwise if the hook is used inside a useEffect, it will lead to an infinite loop
  const memoizedFn = useCallback(
    async (request) => {
      return makeRequest({
        ...request,
        headers: {
          ...request.headers,
          // Add the Authorization header to the existing headers
          Authorization: `Bearer ${token}`,
        },
      });
    },
    [token]
  );
  return {
    requestMaker: memoizedFn,
  };
};

export default useRequest;