import React, { useEffect, createContext, useState } from "react";
import { Outlet, useParams, useSearchParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import LoadingBox from "../components/LoadingBox";
import useRequest from "../api/useRequest";
import { userApi } from "../api/requestFactory";

export const UserContext = createContext();

export const UserContextComponent = ({ children }) => {
  const [userData, setUserData] = useState(undefined)
  const [ownedGuilds, setOwnedGuilds] = useState(undefined)
  const [managedGuilds, setManagedGuilds] = useState(undefined)
  const [currentGuild, setCurrentGuild] = useState(undefined)
  const [isLoadingUser, setIsLoadingUser] = useState(true)
  const navigate = useNavigate();
  const { requestMaker } = useRequest();
  const { guildId } = useParams()


  const refreshUserData = async () => {
    try {
      setIsLoadingUser(true)
      let response = await requestMaker(userApi.get())
      setUserData(response.data)
      setIsLoadingUser(false)
      const guildResponse = await requestMaker(userApi.getOwnedGuilds())
      setOwnedGuilds(guildResponse.guilds)
      setManagedGuilds(guildResponse.guilds.filter((guild) => guild.is_managed))
    } catch (error) {
      setIsLoadingUser(false)
      console.error(error);
      if (error.code === 404){
        navigate('/integration')
      }
    }
  }

  useEffect(() => {
    refreshUserData()
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!guildId || !managedGuilds) return
    if (userData?.email === 'michael@botdisco.com') return
    const newCurrentGuild = managedGuilds.find((guild) => guild.id === guildId)
    // If the current guildId is not one of the managed guilds, redirect to dashboard and throw error
    if (!newCurrentGuild) {
      navigate('/dashboard')
      return
    }
    setCurrentGuild(newCurrentGuild)
  }, [guildId, managedGuilds, navigate])

  if (isLoadingUser || !userData) {
    return <LoadingBox />
  }

  return <UserContext.Provider value={{ userData, ownedGuilds, managedGuilds, currentGuild }}>{children || <Outlet />}</UserContext.Provider >
}