import configJson from './auth_config.json';

export function getConfig() {
    let audience = '';
    let apiOrigin = '';
    let clientId = '';
    let loginUrl = '';
    let blazeUrl = '';
    let tokenName = '';
    let botdiscoClientId = '';
    let dbApi = '';

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        apiOrigin = configJson.devApiOrigin;
        audience = configJson.devAudience;
        clientId = configJson.devClientId;
        blazeUrl = configJson.devBlazeUrl;
        loginUrl = configJson.devLoginUrl;
        tokenName = configJson.devToken;
        botdiscoClientId = configJson.devBotdiscoClientId;
        dbApi = configJson.devDbApi;
    } else if (process.env.REACT_APP_STAGE === 'staging') {
        apiOrigin = configJson.stageApiOrigin;
        audience = configJson.stageAudience;
        clientId = configJson.stageClientId;
        blazeUrl = configJson.stageBlazeUrl;
        loginUrl = configJson.stageLoginUrl;
        tokenName = configJson.stageToken;
        botdiscoClientId = configJson.stageBotdiscoClientId;
        dbApi = configJson.stageDbApi;
    } else {
        apiOrigin = configJson.prodApiOrigin;
        audience = configJson.prodAudience;
        clientId = configJson.prodClientId;
        blazeUrl = configJson.prodBlazeUrl;
        loginUrl = configJson.prodLoginUrl;
        tokenName = configJson.prodToken;
        botdiscoClientId = configJson.prodBotdiscoClientId;
        dbApi = configJson.prodDbApi;
    }

    return {
        apiOrigin: apiOrigin,
        clientId: clientId,
        loginUrl: loginUrl,
        blazeUrl: blazeUrl,
        tokenName: tokenName,
        botdiscoClientId: botdiscoClientId,
        dbApi: dbApi,
        ...(audience ? { audience } : null),
    };
}