const API_VERSION = 'api/v1';

// This is endpoint you want when getting, it returns the user's role with the bot
class UserGuildApi {
  get = (guildId) => ({ method: 'get', url: `${API_VERSION}/user-guild/${guildId}/` });
}

class BotApi {
  create = (botData) => ({ method: 'post', url: `${API_VERSION}/bot/`, data: botData });
  checkToken = (botData) => ({ method: 'post', url: `${API_VERSION}/bot/check-token/`, data: botData });
  checkIntents = (botData) => ({ method: 'post', url: `${API_VERSION}/bot/check-intents/`, data: botData });
}

class GuildApi {
  connectGuild = (guildId) => ({ method: 'post', url: `${API_VERSION}/connect-guild/`, data: { guildId } });
  getStripeUrl = (guildId, returnUrl) => ({ method: 'get', url: `${API_VERSION}/create-customer-portal-session/${guildId}/?returnUrl=${returnUrl}` });
  update = (guildId, data) => ({ method: 'put', url: `${API_VERSION}/guild/${guildId}/`, data: data });

}

class FlowApi {
  create = (guildId, flowData) => ({ method: 'post', url: `${API_VERSION}/guild/${guildId}/flow/`, data: flowData });
  delete = (guildId, flowId) => ({ method: 'delete', url: `${API_VERSION}/guild/${guildId}/flow/${flowId}/` });
  update = (guildId, flowId, flowData) => ({ method: 'put', url: `${API_VERSION}/guild/${guildId}/flow/${flowId}/`, data: flowData });
}

class UserApi {
  validateToken = () => ({ method: 'get', url: `${API_VERSION}/user/validate-token/` });
  integrateDiscord = (code) => ({ method: 'post', url: `${API_VERSION}/user/integrate-discord/`, data: { code } });
  get = () => ({ method: 'get', url: `${API_VERSION}/user/` });
  getOwnedGuilds = () => ({ method: 'get', url: `${API_VERSION}/user/get-guilds/` });
}

class LogsApi {
  get = (guildId) => ({ method: 'get', url: `${API_VERSION}/logs/${guildId}/` });
  markAllViewed = (guildId) => ({ method: 'put', url: `${API_VERSION}/logs/${guildId}/mark-all-viewed/` });
}

class MembersApi {
  get = (guildId) => ({ method: 'get', url: `${API_VERSION}/guild/${guildId}/member/` });
}

class FieldsApi {
  list = (guildId) => ({ method: 'get', url: `${API_VERSION}/guild/${guildId}/field/` });
  create = (guildId, fieldData) => ({ method: 'post', url: `${API_VERSION}/guild/${guildId}/field/`, data: fieldData });
  update = (guildId, fieldId, fieldData) => ({ method: 'put', url: `${API_VERSION}/guild/${guildId}/field/${fieldId}/`, data: fieldData });
  delete = (guildId, fieldId) => ({ method: 'delete', url: `${API_VERSION}/guild/${guildId}/field/${fieldId}/` });
}

class MemberFieldsApi {
  update = (guildId, fieldId, memberId, memberFieldData) => ({ method: 'post', url: `${API_VERSION}/guild/${guildId}/field/${fieldId}/member/${memberId}/`, data: memberFieldData });
}
class TestExternalRequestApi {
  post = (data) => ({ method: 'post', url: `${API_VERSION}/test-external-request/`, data: data });
}

class QnaApi {
  create = (guildId, data) => ( { method: 'post', url: `${API_VERSION}/guild/${guildId}/add-new-qna-pair/`, data: data });
  get = (guildId) => ( { method: 'get', url: `${API_VERSION}/guild/${guildId}/get-qna-data/` });
  update = (guildId, questionId, data) => ( { method: 'put', url: `${API_VERSION}/guild/${guildId}/update-qna-pair/${questionId}/`, data: data })
  delete = (guildId, questionId) => ({ method: 'delete', url: `${API_VERSION}/guild/${guildId}/delete-qna-pair/${questionId}/`});
}

export const userGuildApi = new UserGuildApi()
export const botApi = new BotApi()
export const guildApi = new GuildApi()
export const flowApi = new FlowApi()
export const userApi = new UserApi()
export const logsApi = new LogsApi()
export const membersApi = new MembersApi()
export const fieldsApi = new FieldsApi()
export const memberFieldsApi = new MemberFieldsApi()
export const testExternalRequestApi = new TestExternalRequestApi()
export const qnaApi = new QnaApi() 
