import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getConfig } from '../config';
import LoadingBox from "../components/LoadingBox";
import Cookies from 'js-cookie';
import useRequest from "../api/useRequest";
import { userApi } from "../api/requestFactory";

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const { requestMaker } = useRequest();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // use env var for this
  const { loginUrl, tokenName } = getConfig();

  useEffect(() => {
    const validateToken = async () => {
      const token = Cookies.get(tokenName);
      if (token) {
        try {
          const response = await requestMaker(userApi.validateToken())
          const user_id = response.data.blaze_id
          if (user_id) {
            setIsAuthenticated(true);
          } else {
            // Token validation failed, redirect to login page
            window.location.href = loginUrl
          }
        } catch (error) {
          console.error('Error validating token:', error);
          // Token validation failed, redirect to login page
          window.location.href = loginUrl
        }
      } else {
        // Token does not exist, redirect to login page
        window.location.href = loginUrl
      }
      setIsLoading(false);
    };
    validateToken();
  }, [navigate]);

  if (isLoading) {
    return <LoadingBox />;
  }

  return isAuthenticated && <>{children}</>;
};

export default AuthProvider;
